const basicIncomeLangMap = {
    english: 'Basic Income',
    spanish: 'Renta Básica',
    french: 'Revenu De Base',
    german: 'Grundeinkommen',
    portuguese: 'Renda Básica',
    italian: 'Reddito Di Base',
    dutch: 'Basisinkomen',
    russian: 'Базовый Доход',
    japanese: 'ベーシック インカム',
    chinese: '基本收入',
    korean: '기본소득',
    hindi: 'मूल आय',
    arabic: 'الدخل الأساسي',
    swedish: 'Basinkomst',
    finnish: 'Perustulo',
    norwegian: 'Grunninntekt',
    danish: 'Basisindkomst',
    polish: 'Dochód Podstawowy',
    turkish: 'Temel Gelir',
    greek: 'Βασικό Εισόδημα',
}

export default basicIncomeLangMap
